<template>
  <footer class="footer">
    <div class="ml-5">
      <p class="title">Stonehurst Solutions</p>
      <p class="sub-title">© {{ new Date().getFullYear() }} All right reserverd</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 5rem;
  background-color: $navbar-background-color;
}

footer div p {
  margin: 0%;
  color: $white;

  &.title {
    font-size: 14px;
  }
  &.sub-title {
    font-size: 10px;
  }
}
</style>
