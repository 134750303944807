<template>
  <div>
    <nav-bar />
    <notifications
      class="vue-notification-container"
      position="top center"
      group="alert"
    ></notifications>
    <notifications
      class="vue-notification-container"
      position="top center"
      group="loading"
    >
      <template slot="body" slot-scope="props">
        <div class="vue-notification-template vue-notification">
          <div class="notification-title">
            {{ props.item.title }}
            &nbsp;
            <span class="icon">
              <icon :icon="['fas', 'spinner']" />
            </span>
          </div>
          <div class="notification-content" v-html="props.item.text"></div>
        </div>
      </template>
    </notifications>
    <section class="main-layout-container" :class="{'padding': padding}">
      <slot />
    </section>
    <footer-tra />
  </div>
</template>

<script>
import NavBar from '@/components/NavBar';
import FooterTra from '@/components/Footer';
export default {
  components: {
    NavBar,
    FooterTra
  },
  props: {
    padding: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
.vue-notification-container {
  top: 25px !important;
}
</style>

<style lang="scss" scoped>
  .main-layout-container {
    display: flex;
    min-height: calc(100vh - 160px);
  }
  .padding {
    padding: 1rem 0;
  }
</style>
