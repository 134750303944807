<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="mx-5" style="display: flex; margin: 0; width: 100%;">
      <div class="navbar-brand">
        <a class="navbar-item" href="/">
          <img src="/assets/images/brand.png" />
        </a>
      </div>
      <div v-show="$route.meta.auth" class="navbar-start">
        <div
          class="navbar-item"
          :class="{active: $route.name.indexOf('builder') !== -1 }"
        >
          <a href="/builder">
            <span>NEW FORM</span>
          </a>
        </div>
        <div class="navbar-item" :class="{ active: $route.path === '/forms' }">
          <router-link to="/forms" v-if="!isBuilderView">
            <span>MY FORMS</span>
          </router-link>
          <a href="/forms" v-else>
            <span>MY FORMS</span>
          </a>
        </div>
        <template v-if="isAdmin">
          <div class="navbar-item" :class="{ active: $route.path === '/users' }">
            <router-link to="/users" v-if="!isBuilderView">
              <span>USERS</span>
            </router-link>
            <a href="/users" v-else>
              <span>USERS</span>
            </a>
          </div>
          <div class="navbar-item" :class="{ active: $route.path === '/groups' }">
            <router-link to="/groups" v-if="!isBuilderView">
              <span>GROUPS</span>
            </router-link>
            <a href="/groups" v-else>
              <span>GROUPS</span>
            </a>
          </div>
          <div class="navbar-item" :class="{ active: $route.path === '/companies' }">
            <router-link to="/companies" v-if="!isBuilderView">
              <span>COMPANIES</span>
            </router-link>
            <a href="/companies" v-else>
              <span>COMPANIES</span>
            </a>
          </div>
          <div class="navbar-item" :class="{ active: $route.path === '/custom-actions' }">
            <router-link to="/custom-actions" v-if="!isBuilderView">
              <span>CUSTOM ACTIONS</span>
            </router-link>
            <a href="/custom-actions" v-else>
              <span>CUSTOM ACTIONS</span>
            </a>
          </div>
        </template>
          <div class="navbar-item" :class="{ active: $route.path === '/code-lists' }">
            <router-link to="/code-lists" v-if="!isBuilderView">
              <span>CODE LISTS</span>
            </router-link>
            <a href="/code-lists" v-else>
              <span>CODE LISTS</span>
            </a>
          </div>
      </div>
      <div class="navbar-end">
        <div v-show="$route.meta.auth" class="buttons">
          <div id="drop" class="navbar-item has-dropdown is-hoverable">
            <figure class="image is-32x32">
              <img class="is-rounded" src="/assets/images/avatar.png" v-if="!user.avatar" />
              <img class="is-rounded" :src="user.avatar" v-else />
            </figure>
            <div class="navbar-dropdown is-right">
              <router-link class="navbar-item" to="/profile" v-if="!isBuilderView">
                <span>Profile</span>
              </router-link>
              <a href="/profile" class="navbar-item" v-else>
                <span>Profile</span>
              </a>
              <!-- Log out -->
              <a class="navbar-item is-danger" @click="signOut()" href="javascript:void(0)">
                <span>Sign Out</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import axios from 'axios';
export default {
  name: 'NavBar',
  data() {
    return {
      user: $cookies.get($formsConfig.core.cookieName)
    };
  },

  computed: {
    isBuilderView() {
      return this.$route.name.indexOf('builder') !== -1;
    },
    isAdmin() {
      return this.user.role === 'admin';
    },
  },
  methods: {
    signOut() {
      let self = this;
      axios
        .post($formsConfig.core.api.signout)
        .then(() => {
          this.$cookieHandler.remove($formsConfig.core.cookieName);
          self.$router.push('/login');
        })
        .catch(ex => {
          console.error(ex);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
$navbar-dropdown-background-color: $button-background;
$navbar-dropdown-item-hover-background-color: $background-panels;

#drop {
  padding: 0.5rem 0.75rem;
}

.navbar-dropdown {
  top: 70%;
  left: auto;
}

#drop {
  padding-right: 0;

  > a {
    margin-right: 0;
  }
}

.navbar-start .navbar-item {
  a[href] {
    padding-right: 16px;
    padding-left: 16px;
    font-size: 0.8rem;
    color: white;
  }
}
.navbar-start .navbar-item:hover {
  background-color: #28303f;
}
.navbar-start .navbar-item.active {
  background-color: white;
  a[href] {
    color: #3e4960;
  }
}
.navbar-start .navbar-item::before {
  position: absolute;
  top: 50%;
  left: 0;
  height: 1em;
  content: ' ';
  background-color: rgba(255, 255, 255, 0.2);
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  width: 1px;
  z-index: 1;
}
</style>
